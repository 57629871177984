import { default as _2048AdGfWCarhrMeta } from "/home/runner/work/website2/website2/pages/2048.vue?macro=true";
import { default as blogerTGWsZsrwMeta } from "/home/runner/work/website2/website2/pages/blog.vue?macro=true";
import { default as CatAndMouseRuPStGzoGwMeta } from "/home/runner/work/website2/website2/pages/CatAndMouse.vue?macro=true";
import { default as CodeEditorVAnQx1reiIMeta } from "/home/runner/work/website2/website2/pages/CodeEditor.vue?macro=true";
import { default as gamesZQoz57ibG3Meta } from "/home/runner/work/website2/website2/pages/games.vue?macro=true";
import { default as indexiElkeMlYbeMeta } from "/home/runner/work/website2/website2/pages/index.vue?macro=true";
import { default as linkse8dDIvdJgQMeta } from "/home/runner/work/website2/website2/pages/links.vue?macro=true";
import { default as PongyIlJUTRMagMeta } from "/home/runner/work/website2/website2/pages/Pong.vue?macro=true";
export default [
  {
    name: "2048",
    path: "/2048",
    component: () => import("/home/runner/work/website2/website2/pages/2048.vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/runner/work/website2/website2/pages/blog.vue")
  },
  {
    name: "CatAndMouse",
    path: "/CatAndMouse",
    component: () => import("/home/runner/work/website2/website2/pages/CatAndMouse.vue")
  },
  {
    name: "CodeEditor",
    path: "/CodeEditor",
    component: () => import("/home/runner/work/website2/website2/pages/CodeEditor.vue")
  },
  {
    name: "games",
    path: "/games",
    component: () => import("/home/runner/work/website2/website2/pages/games.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexiElkeMlYbeMeta || {},
    component: () => import("/home/runner/work/website2/website2/pages/index.vue")
  },
  {
    name: "links",
    path: "/links",
    component: () => import("/home/runner/work/website2/website2/pages/links.vue")
  },
  {
    name: "Pong",
    path: "/Pong",
    component: () => import("/home/runner/work/website2/website2/pages/Pong.vue")
  }
]